import { mapHelper } from "@/utils/common";

const auditStatusOptions = [
  {
    value: 0,
    label: "—",
  },
  {
    value: 1,
    label: "待审核",
  },
  {
    value: 2,
    label: "审核通过",
  },
  {
    value: 3,
    label: "审核驳回",
  },
];
const { map: auditStatusMap, setOps: auditStatusOps } =
  mapHelper.setMap(auditStatusOptions);
//应用审核状态
const newAuditStatusOptions = [
  {
    value: 1,
    label: "待审核",
  },
  {
    value: 2,
    label: "审核通过",
  },
  {
    value: 3,
    label: "审核驳回",
  },
];
const { map: newAuditStatusOptionsMap, setOps: newAuditStatusOptionsOps } =
  mapHelper.setMap(newAuditStatusOptions);
const verifyAuditStatus = [
  {
    value: 2,
    label: "审核通过",
  },
  {
    value: 3,
    label: "审核驳回",
  },
];
const { map: verifyAuditStatusMap, setOps: verifyAuditStatusOps } =
  mapHelper.setMap(verifyAuditStatus);
//页面类型
const pagesType = [
  {
    value: 1,
    label: "小程序原生页面",
  },
  {
    value: 2,
    label: "h5页面",
  },
  {
    value: 3,
    label: "第三方h5页面",
  },
  {
    value: 4,
    label: "第三方小程序",
  },
];

const { map: pagesTypeMap, setOps: pagesTypeOps } = mapHelper.setMap(pagesType);

//入口类型
const inType = [
  {
    value: 1,
    label: "小程序",
  },
];
const { map: inTypeMap, setOps: inTypeOps } = mapHelper.setMap(inType);
//是否首页展示
const isShowHomeType = [
  {
    value: 1,
    label: "是",
  },
  {
    value: 0,
    label: "否",
  },
];
const { map: isShowHomeTypeMap, setOps: isShowHomeTypeOps } =
  mapHelper.setMap(isShowHomeType);

//是否配置域名白名单
const isSetOps = [
  {
    value: 1,
    label: "是",
  },
  {
    value: 2,
    label: "否",
  },
];
const { map: isSetOpsMap, setOps: isSetOpsOps } = mapHelper.setMap(isSetOps);

//审核状态
const isHandleOps = [
  {
    value: 0,
    label: "未授权",
  },
  {
    value: 1,
    label: "待审核",
  },
  {
    value: 2,
    label: "审核通过",
  },
  {
    value: 3,
    label: "审核驳回",
  },
];
const { map: isHandleOpsMap, setOps: isHandleOpsOps } =
  mapHelper.setMap(isHandleOps);
//账号类型
const accountOps = [
  {
    value: 0,
    label: "开发者",
  },
  {
    value: 1,
    label: "社区用户",
  },
];
const { map: accountOpsMap, setOps: accountOpsOps } =
  mapHelper.setMap(accountOps);

//建设方式
const buildTypeList = [
  {
    value: 1,
    label: "区域统建",
  },
  {
    value: 2,
    label: "特色自建",
  },
];
const { map: buildTypeListMap, setOps: buildTypeListOps } =
  mapHelper.setMap(buildTypeList);
//有效状态
const validStatusList = [
  {
    value: 1,
    label: "启用",
  },
  {
    value: 0,
    label: "禁用",
  },
];
const { map: validStatusListMap, setOps: validStatusListOps } =
  mapHelper.setMap(validStatusList);
//是否推荐
const isRecommendList = [
  {
    value: 1,
    label: "是",
  },
  {
    value: 0,
    label: "否",
  },
];
const { map: isRecommendListMap, setOps: isRecommendListOps } =
  mapHelper.setMap(isRecommendList);
//上限审核状态
const jusgeStatusList = [
  {
    value: 0,
    label: "未申请",
  },
  {
    value: 1,
    label: "待审核",
  },
  {
    value: 2,
    label: "审核通过",
  },
  {
    value: 3,
    label: "审核驳回",
  },
];
const { map: jusgeStatusListMap, setOps: jusgeStatusListOps } =
  mapHelper.setMap(jusgeStatusList);

//上限审核结果
const jusgeStatusRea = [
  {
    value: 2,
    label: "审核通过",
  },
  {
    value: 3,
    label: "审核驳回",
  },
];
const { map: jusgeStatusReaMap, setOps: jusgeStatusReaOps } =
  mapHelper.setMap(jusgeStatusRea);
//  房屋/人筛选
const selectionList = [
  {
    value: 1,
    label: "人员数据",
  },
  {
    value: 2,
    label: "房屋数据",
  },
  {
    value: 3,
    label: "车辆信息",
  },
  {
    value: 11,
    label: "空间信息",
  },
  {
    value: 21,
    label: "内容安全",
  },
  {
    value: 41,
    label: "三维资产",
  },
  {
    value: 42,
    label: "推送数据",
  },
  {
    value: 101,
    label: "省级基础数据",
  },
  {
    value: 102,
    label: "省级运营数据",
  },
  {
    value: 103,
    label: "九大场景数据",
  },
];
const { map: selectionListMap, setOps: selectionListOps } =
  mapHelper.setMap(selectionList);
export {
  auditStatusOptions,
  auditStatusMap,
  newAuditStatusOptions,
  verifyAuditStatus,
  verifyAuditStatusMap,
  pagesType,
  pagesTypeMap,
  inType,
  inTypeMap,
  isShowHomeTypeMap,
  isShowHomeType,
  isSetOps,
  isHandleOpsMap,
  isHandleOps,
  accountOps,
  accountOpsMap,
  buildTypeList,
  buildTypeListMap,
  validStatusList,
  validStatusListMap,
  isRecommendList,
  jusgeStatusListMap,
  jusgeStatusList,
  jusgeStatusRea,
  selectionListMap,
  selectionListOps
};
